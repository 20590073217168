<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <base-material-alert
      v-if="activateAlert"
      color="primary"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ displayMessage }}
    </base-material-alert>
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Login
              </h1>
            </div>
          </template>
          <v-spacer />
          <v-row justify="center">
            <v-col
              class="--v-success-base"
              cols="12"
              sm="12"
              md="6"
            >
              Please select your table:
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <div style="text-align: center">
                <v-autocomplete
                  v-model="tableNumber"
                  :items="numbers"
                  :dark="false"
                  color="green"
                  max-width="70%"
                  item-color="green"
                />
              </div>
            </v-col>
          </v-row>

          <v-card-text class="text-center">
            <pages-btn
              large
              color=""
              depressed
              class="v-btn--text success--text"
              @click="redirect()"
            >
              Let's Go
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import router from '@/router'
  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('./components/Btn'),
    },

    data: () => ({
      activateAlert: false,
      displayMessage: '',
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }),

    computed: {
      tableNumber: {
        get () { return this.$store.getters.get_table },
        set (val) {
          this.$store.commit('SET_TABLE', val)
        },
      },
    },

    methods: {
      redirect () {
        if (this.tableNumber !== 0) {
          router.push('/')
        } else {
          this.displayMessage = 'Selectati masa dvs.'
          this.activateAlert = true
        }
      },
    },
  }
</script>
